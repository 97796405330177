<template>
    <div>
        <loader :is-visible="isLoading"></loader>
        <section class="tab mt-2">
                            <div class="tab-style" v-if="!HideCtrl">
                                <div class="tab_container mb-0" id="tabsdata">
                                    <ul class="nav nav-tabs all-tabs responsive-tabs cust">
                                        <li class="nav-item">
                                            <a class="nav-link" id="st1" v-on:click="stepClick(1)" v-bind:class="{'active show': TabSelected== 1}" data-toggle="tab" href="#step1"> <span class="numb">1</span>Template</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" id="st2" v-on:click="stepClick(2)" v-bind:class="{'active show': TabSelected== 2,disabled:step2==null}" data-toggle="tab" v-bind:href="step2"><span class="numb">2</span>Project</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" id="st3" v-on:click="stepClick(3)" v-bind:class="{'active show': TabSelected== 3,disabled:step3==null}" data-toggle="tab" v-bind:href="step3"><span class="numb">3</span>Team</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="tab-content">                              
                                <a v-if="HideCtrl" data-toggle="tab" href="#step3" class="nav-link card mobile-tab-accordian proj-tab p-2" v-on:click="stepClick(1)" v-bind:class="{'active show': TabSelected== 1}">
                                    <div class="d-block"><span class="numb">1</span>Template</div>
                                </a>
                                <div id="step1" class="tab-pane" v-bind:class="{'active show': TabSelected== 1}">
                                    <div class="container-fluid p-0">
                                        <Form v-slot="{valid,errors}" ref="ProjectForm">
                                            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 float-left p-0" v-if="!View">
                                                <div class="col-sm-12 col-md-8 col-lg-8 col-xl-8 searchpanel tablehead search float-left p-0">
                                                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 Search-panel float-left py-2 p-0">
                                                        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 float-left pr-1 pl-0">
                                                            <div class="form-group">
                                                                <input class="form-control" v-model="SearchText" type="text" placeholder="Search" id="search"
                                                                       autocomplete="off" name="search">
                                                            </div>
                                                        </div>
                                                        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 float-left pl-0">
                                                            <div class="background-0 text-left form-check-inline">
                                                               <a id="Go" title="Search" v-on:click="SearchTemplate()" class="btn btn-success mr-1 px-4"><i class="fa fa-search text-white"></i></a>
                                                               <a title="Clear" href="javascript:;" v-on:click="ResetTemplate()" class="btn btn-danger clearsearchtext px-4"><i class="fa fa-refresh"></i></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 searchpanel tablehead search float-left p-0">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 float-left p-0 mt-2">
                                                       <a href="javascript:;" v-if="!isEditCustomise" v-on:click="Customise()" class="btn btn-info float-right"><i class="fa fa-pencil pr-2"></i>Customise</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 float-left mt-3 p-0 float-left">
                                                <div class="heading-border">
                                                    <h5 class="h5">
                                                       <span> General Template</span><span class="text-danger">*</span>
                                                       <div class="float-right ml-4">
                                                       </div>
                                                    </h5>
                                                </div>
                                                <div class="col-12 float-left p-0" id="TemplateList" style="max-height:500px; overflow-y:auto" v-if="!View">
                                                        <div class="conversation-box-con w-100 float-left mt-3" v-for="(item,index) in TemplateList" :key="index">
                                                        <div class="conversation-box" style="cursor:pointer" v-bind:class="{'gen-templateadded':item.SetTemplate==1,'defaulttemplate':item.name=='Default Template'}" v-on:click="ApplyTemplate(item,$event)" v-bind:id="'temp_'+index">
                                                            <div class="name-circle light-grey">
                                                               <i class="fa fa-share-square-o text-white"></i>
                                                            </div>
                                                            <div class="conversation-header w-60">
                                                                <span class="sender-name">
                                                                   <span>{{item.name}}</span>
                                                                   <span class="sender-department">
                                                                     &nbsp; {{$options.filters.formatDateTime(item.created_at)}} 
                                                                    </span>
                                                                </span>
                                                            </div>
                                                            <div class="conversation-content w-60">
                                                               <p v-bind:title="item.description">                                                           
                                                               {{ TruncateStr(item.description,150) }}
                                                               </p>
                                                            </div>
                                                            <div class="proj-convboxact float-left">
                                                               <a title="View Template" href="javascript:;" v-on:click="ViewTemplate(item.id,item.EditableData)" class="round-icon-small btn-primary-light"><i class="fa fa-eye"></i></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="text-center text-danger d-flex align-items-center justify-content-center borderbababa p-2 mt-2" v-if="TemplateList.length==0">
                                                    No Record found
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                    <div class="container-fluid p-0 float-left" v-if="View">
                                       <Form v-slot="{valid,errors}" ref="form">
                                        <div class="row mt-2">
                                            <div class="col-lg-12">
                                                <div class="form-group">
                                                    <label>Name:<span class="text-danger">*</span></label>
                                                    <Field  name="Title" class="w-100" :rules="{ 'required': true }" v-slot="{ errors }">
                                                        <input  name="TemplateName"  type="text" v-bind:class="{ 'form-control w-100': true, 'is-invalid': errors[0] }"
                                                         v-model="TemplateName" />
                                                        <span v-if="errors[0]" name="TemplateName" class="invalid-feedback">{{ errors[0] }}</span>
                                                    </Field>                                                    
                                                </div> 
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="form-group">
                                                   <label>Description:</label>
                                                   <Field class="w-100" name="TemplateDesc" :rules="{ 'required': false, 'max': 500 }" v-slot="{ errors }">
                                                        <textarea cols="3"  rows="3" v-model="TemplateDescription" v-bind:class="{ 'form-control w-100': true }"/>
                                                        <span v-if="errors[0]" name="TemplateDesc" class="invalid-feedback">{{ errors[0] }}</span>
                                                    </Field>                                                   
                                                </div>
                                            </div>
                                        </div>
                                        <div class="heading-border mb-3">
                                            <h5 class="h5">
                                               <span>Features</span>
                                            </h5>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-4" v-for="(data,TemplateByIdIndex) in TemplateById" :key="TemplateByIdIndex">
                                                <div class="form-group">
                                                    <label>{{data.FEATURE}}:</label>
                                                    <div class="form-group">
                                                        <label class="switch" v-bind:class="{'disabled':ClickCustomise==false}">
                                                            <input type="checkbox" v-bind:disabled="data.FEATURE=='Overview'" v-bind:value="data.IS_ENABLED" v-model="data.IS_ENABLED" />
                                                            <span class="slider round" v-bind:class="{'bggreycolor':data.FEATURE=='Overview'}">
                                                               <span  class="slider-yes"> Yes</span>
                                                               <span class="slider-no">No</span>
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </form>
                                    </div>
                                    <hr>
                                    <div class="col-lg-12 float-left p-0">
                                        <div class="row flex-row-reverse">
                                            <div class="col-lg-6 text-right">
                                                <a href="javascript:;" v-show="View" id="btnCancel" v-on:click="CancelTemplate()" title="Cancel" class="btn btn-danger mr-1" data-toggle-tooltip="tooltip" data-original-title="Cancel"><i class="fa fa-close pr-2"></i>Cancel</a>
                                                <a href="javascript:;" id="btnNext" v-on:click="Next()" title="Next" class="btn btn-primary " name="btnNext" data-toggle-tooltip="tooltip" data-original-title="Next"><span>Next</span><i class="fa fa-angle-double-right pl-2"></i></a>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="py-2">
                                                   <span class="mandatory">Fields marked with an asterisk (*) are mandatory.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <a v-if="HideCtrl" data-toggle="tab" href="#step2" class="nav-link card mobile-tab-accordian proj-tab p-2" v-on:click="stepClick(2)" v-bind:class="{'active show': TabSelected== 2}"><div class="d-block"><span class="numb">2</span>Project</div></a>
                                <div id="step2" class="tab-pane" v-bind:class="{'active show': TabSelected== 2}">
                                    <div class="container-fluid p-0" style="min-height:416px;">
                                        <div class="container-fluid p-0" style="min-height:416px;">
                                            <Form v-slot="{valid,errors}" ref="ProjectData">
                                                <div class="container-fluid p-0" id="ProjectDataList" style="max-height:600px;overflow-y:auto; overflow-x:hidden;">                                            
                                                        <div class="w-100">
                                                        <DynamicForm subModuleCode="Project_Detail" :schema="GroupBy" v-if="GroupBy.length > 0" 
                                                        v-on:onChangeOpenAddChannelTag="onChangeOpenAddChannelTag" @CloseAddChannelTag="CloseAddChannelTag"
                                                        v-on:setSelectedValue="setSelectedValue(item, item.value)">
                                                    </DynamicForm> 
                                                    </div>
                                                </div>
                                                <div class="row flex-row-reverse">
                                                      <div class="col-lg-6  text-right">                                                        
                                                        <a href="javascript:void(0)" class="btn btn-secondary mr-1" title="Cancel" @click="BackStep">
                                                          <em class="fa fa-angle-double-left  mr-1"></em>Back
                                                        </a> 
                                                        <a
                                                          href="javascript:;"
                                                          v-bind:class="{ disabled: false }"
                                                          v-on:click="NextStep3(valid,errors)"
                                                          class="btn btn-primary mr-1"
                                                          title="Save"
                                                        >
                                                          <em class="fa fa-angle-double-right pl-2 pr-2"></em>Next
                                                        </a>               
                                                      </div>
                                                      <div class="col-lg-6">
                                                          <div class="py-2">
                                                           <small class="text-danger">{{$t('MandatoryString')}}</small>
                                                          </div>
                                                        </div>              
                                                    </div>                                                 
                                            </Form>
                                        </div>
                                    </div>
                                </div>                                
                                <a v-if="HideCtrl" data-toggle="tab" href="#step3" class="nav-link card mobile-tab-accordian proj-tab p-2" v-on:click="stepClick(3)" v-bind:class="{'active show': TabSelected== 3}">
                                    <div class="d-block">
                                        <span class="numb">3</span>{{$t("Team")}}
                                    </div> 
                                </a>                                                  
                                <div id="step3" class="tab-pane" v-bind:class="{'active show': TabSelected== 3}">
                                    <div class="container-fluid p-0" style="min-height:416px;" v-if="UserForm==false">
                                        <div class="container-fluid p-0" style="min-height:416px;">                                            
                                            <div class="col-md-12 p-0">
                                                <div class="heading-border">
                                                    <h5 class="h5">
                                                        <span>{{$t('AddTeamMembers')}}</span>
                                                        <small class="float-right">                    
                                                                <a href="javascript:void(0)" title="" @click="AddUser" data-toggle-tooltip="tooltip" data-original-title="Add User">
                                                                    <em class="fa fa-plus pr-1"></em>Add User
                                                                </a>
                                                        </small>
                                                    </h5>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 searchpanel tablehead search float-left p-0">
                                                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 Search-panel float-left py-3 p-0">
                                                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 float-left">
                                                        <div class="row mt-0">
                                                            <div :class="{'col-sm-12 col-md-4 col-lg-4 col-xl-4 float-left pr-1 pl-0 mb-1':OverView == true, 'col-6':OverView != true}" class="float-left pr-1 pl-0">
                                                                <div class="form-group">
                                                                    <input class="form-control" type="text" id="txtTeamSearchPop" value="Search Project Team" placeholder="Search By MemberName">
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 float-left pr-1 pl-0 mb-1" v-if="OverView">
                                                                <div class="form-group">                                     
                                                                    <Field :rules="{ 'required': false }" class="w-100" name="UserId" v-slot="{ errors }">      
                                                                        <select v-model="UserId" v-bind:class="{ 'form-control w-100': true }">                                               
                                                                            <option value="all">All</option>
                                                                            <option value="v">Virtual User</option>
                                                                        </select>
                                                                        <span v-if="errors[0]" name="UserId" class="invalid-feedback d-block">{{ errors[0] }}</span> 
                                                                    </Field>  
                                                                </div>
                                                            </div>
                                                            <div :class="{'col-sm-12 col-md-4 col-lg-4 col-xl-4 float-left pr-1 pl-0 mb-1':OverView == true, 'col-6':OverView != true}" class="float-left pr-1 pl-0">
                                                                <div class="background-0 text-left form-check-inline search-btm-btn">
                                                                    <a title="Search" id="SearchTeam_Add" href="javascript:;" class="btn btn-success px-4 mr-2" data-toggle-tooltip="tooltip"><i class="fa fa-search"></i></a>
                                                                    <a title="Clear" id="clearTeam_add" href="javascript:;" class="btn btn-danger clearsearchtext px-4" data-toggle-tooltip="tooltip"><i class="fa fa-refresh"></i></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 p-0">
                                                <div class="listing">
                                                    <div class="table-responsive table-fix-header float-left" style="max-height:530px;">
                                                        <table id="mainTable" class="table table-bordered dt-responsive nowrap mt-0">
                                                            <thead class="thead-bg">
                                                                <tr>
                                                                    <th style="width:50px !important;"  class="text-center"><input class="chkItems setcheckall" type="checkbox" id="chkAll" name="checkbox" data-parent="no" @click="setcheckall"></th>
                                                                    <th style="min-width:150px;"><span>{{$t('MemberName')}}</span></th>
                                                                    <th><span>{{$t('Department')}}</span></th>
                                                                    <th><span>{{$t('Designation')}}</span></th>
                                                                    <th style="min-width:190px;" ><span class="float-left pr-0">Rate Currency</span><span class="float-left text-danger">*</span></th>
                                                                    <th style="min-width:150px;"><span>{{$t('AliasName')}}</span></th>
                                                                    <th class="text-center" style="min-width:170px;"><span>{{$t('Show')}} {{$t('AliasName')}}</span></th>
                                                                    <th class="text-center" style="min-width:100px;"><span>{{$t('IsPM')}}</span></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-if="trnorecords">
                                                                    <td class="text-danger text-center no-record">
                                                                        {{$t('NoRecordfound')}}
                                                                    </td>
                                                                </tr>
                                                                <tr v-for="(item,index) in ProjectTeamMembers" :key="index">
                                                                    <td style="width:50px !important;" class="text-center">
                                                                        <input id="checkboxid" showAlias="no" isPM="yes" class="chkItems addmem checkboxid"  :value="item.USER_ID" name="DeleteInputs" type="checkbox" data-parent="no" @click="setcheckSingle(index)">
                                                                    </td>
                                                                    <td>
                                                                        {{item.MEMBERNAME}}
                                                                    </td>
                                                                    <td>
                                                                        {{item.DEPARTMENT==null?'--':item.DEPARTMENT}}
                                                                    </td>
                                                                    <td>
                                                                        {{item.DESIGNATION==null?'--':item.DESIGNATION}}
                                                                    </td>
                                                                    <td>
                                                                        <div class="form-group"> 
                                                                            <input v-bind:class="{ 'form-control': true}"  type="text"  v-model="item.ratePerHour"/>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div class="form-group"> 
                                                                            <input v-bind:class="{ 'form-control': true}"  type="text"  v-model="item.ALIASNAME"/>
                                                                        </div>
                                                                    </td>
                                                                    <td class="text-center">
                                                                        <input id="checkboxPM" class="chkDefault checkboxPM nochecked"  type="checkbox" :value="item.USER_ID"  @click="checkboxAlias"/>
                                                                    </td>
                                                                    <td class="text-center">
                                                                        <input  class="chkDefault checkboxPM nochecked"  type="checkbox" :value="item.USER_ID"  @click="checkboxPM"/>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row flex-row-reverse mt-2" v-if="isShowSaveBUtton">
                                                <div class="col-lg-6 text-right">
                                                   <div class="search-btm-btn">
                                                   <a href="javascript:;" id="btnBackstep2" v-on:click.prevent="BackStep(2)" class="btn btn-info mr-1" name="btnBackstep2" title="" data-toggle-tooltip="tooltip" data-original-title="Back"><i class="fa fa-angle-double-left  mr-1"></i> <span>Back</span></a>
                                                   <a href="javascript:;" v-bind:class="{disabled: submited}"  class="btn btn-success formbtn widthhalf"  v-on:click="SubmitData()" title="Save"><i class="fa fa-save pr-2"></i>{{$t("Save")}}</a>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="py-2">
                                                       <small class="text-danger">{{$t('MandatoryString')}}</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                   
                                </div>
                                <div class="container-fluid p-0" style="min-height:416px;" v-if="UserForm==true">
                                        <div class="container-fluid p-0" style="min-height:416px;">
                                            <div class="col-md-12 p-0">
                                                <div class="heading-border">
                                                    <h5 class="h5">
                                                        <span>{{$t('AddTeamMembers')}}</span>                                                        
                                                    </h5>
                                                </div>
                                            </div>
                                            <Form ref="AddUserform" v-slot="{ valid,errors}">
                                                <div class="row mt-2">
                                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 float-left">
                                                    <div class="form-group">
                                                        <label>Title:</label>                                      
                                                        <Field :rules="{ 'required': false }" class="w-100" name="Title">      
                                                            <select name="Title" v-model="txtTitle" v-bind:class="{ 'form-control w-100': true }"> 
                                                                <option selected value="">Select Title</option>                                              
                                                                <option v-for="(item, itemIndex) in DDLList" :key="itemIndex" :value="item.user_title_id">{{ item.user_title }}</option>
                                                            </select>                                                            
                                                        </Field> 
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 float-left">
                                                    <div class="form-group">
                                                        <label>Gender:</label>                                      
                                                        <Field :rules="{ 'required': true }" class="w-100" name="Gender">      
                                                            <select name="Gender" v-model="txtGender" v-bind:class="{ 'form-control w-100': true }"> 
                                                                <option selected value="">Select Gender</option>                                                                                                              
                                                                <option  value="M">Male</option>
                                                                <option  value="F">Female</option>
                                                            </select>                                                            
                                                        </Field> 
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 float-left">
                                                    <div class="form-group">                          
                                                        <label>First Name:<span class="text-danger">*</span></label>                                
                                                          <Field name="FirstName" :rules="{'required':true}" v-slot="{errors}">
                                                            <input v-bind:class="{ 'form-control': true, 'is-invalid':errors[0] }" v-model="txtFirstName" type="text"  />                                           
                                                            <span v-if="errors[0]" name="FirstName" class="invalid-feedback">{{errors[0]}}</span>                                
                                                         </Field>     
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 float-left">
                                                    <div class="form-group">                          
                                                        <label>Last Name:<span class="text-danger">*</span></label>                                
                                                          <Field name="LastName" :rules="{'required':true}" v-slot="{errors}">
                                                            <input v-bind:class="{ 'form-control': true, 'is-invalid':errors[0] }" v-model="txtLastName" type="text"  />                                           
                                                            <span v-if="errors[0]" name="LastName" class="invalid-feedback">{{errors[0]}}</span>                                
                                                         </Field>     
                                                    </div>
                                                </div>                                                
                                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 float-left">
                                                    <div class="form-group">                          
                                                        <label>Email:<span class="text-danger">*</span></label>                                
                                                          <Field name="Email" :rules="{'required':true}" v-slot="{errors}">
                                                            <input v-bind:class="{ 'form-control': true, 'is-invalid':errors[0] }" v-model="txtEmail" type="text"  />                                           
                                                            <span v-if="errors[0]" name="Email" class="invalid-feedback">{{errors[0]}}</span>                                
                                                         </Field>     
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 float-left">
                                                    <div class="form-group">
                                                        <label>User Type:<span class="text-danger">*</span></label>                                      
                                                        <Field :rules="{ 'required': true }" class="w-100" name="UserType" v-slot="{ errors }">      
                                                            <select name="status" v-model="txtUserType" v-bind:class="{ 'form-control w-100': true ,'is-invalid':errors[0] }"> 
                                                                <option selected value="">Select User Type</option>                                              
                                                                <option v-for="(item, itemIndex) in GetUserType" :key="itemIndex" :value="item.user_type_id">{{ item.user_type_desc }}</option>
                                                            </select>
                                                            <span v-if="errors[0]" name="UserType" class="invalid-feedback d-block">{{ errors[0] }}</span> 
                                                        </Field> 
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 float-left">
                                                    <div class="form-group">                          
                                                        <label>Password:<span class="text-danger">*</span></label>                                
                                                          <Field name="Password" :rules="{'required':true}" label="Password" v-slot="{errors}">
                                                            <input v-bind:class="{ 'form-control': true, 'is-invalid':errors[0] }" v-model="txtPassword" type="text"  />                                           
                                                            <span v-if="errors[0]" name="Password" class="invalid-feedback">{{errors[0]}}</span>                                
                                                         </Field>     
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 float-left">
                                                    <div class="form-group">                          
                                                        <label>Confirm Password:<span class="text-danger">*</span></label>                                
                                                          <Field name="CPassword" :rules="{'required':true}" label="Confirm Password" v-slot="{errors}">
                                                            <input v-bind:class="{ 'form-control': true, 'is-invalid':errors[0] }" v-model="txtCPassword" type="text"  />                                           
                                                            <span v-if="errors[0]" name="CPassword" class="invalid-feedback">{{errors[0]}}</span>                                
                                                         </Field>     
                                                    </div>
                                                </div>
                                               
                                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 float-left">
                                                    <div class="form-group">
                                                        <label>Role:</label>                                      
                                                        <Field :rules="{ 'required': true }" class="w-100" name="Role">      
                                                            <select name="Role" v-model="txtRole" v-bind:class="{ 'form-control w-100': true }"> 
                                                                <option selected value="">Select Role</option>                                              
                                                                <option v-for="(item, itemIndex) in RoleList" :key="itemIndex" :value="item.role_id">{{ item.role_name }}</option>
                                                            </select>                                                           
                                                        </Field> 
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 float-left">
                                                    <div class="form-group">
                                                        <label>Department:<span class="text-danger">*</span></label>                                      
                                                        <Field :rules="{ 'required': true }" class="w-100" name="Department">      
                                                            <select name="Department" v-model="txtDepartment" v-bind:class="{ 'form-control w-100': true }"> 
                                                                <option selected value="">Select Department</option>                                              
                                                                <option v-for="(item, itemIndex) in GetDetartment" :key="itemIndex" :value="item.department_id">{{ item.department_name }}</option>
                                                            </select>                                                         
                                                        </Field> 
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 float-left">
                                                    <div class="form-group">
                                                        <label>Date of Hire (M/D/YYYY):<span class="text-danger">*</span></label>                                                        
                                                        <Field name="Date" :rules="{ 'required': true }" :label="$t('Date')"
                                                            v-slot="{ field, errorMessage, meta, handleChange }">
                                                            <v-date-picker :popover="popover" :minute-increment="5" v-bind="field"
                                                                class="input-group" v-model="txtUserDate" @update:modelValue="handleChange" >
                                                                <template v-slot="{ inputValue, inputEvents, togglePopover }">
                                                                    <input
                                                                        v-bind:class="{ 'form-control': true, 'is-invalid': errors[0] }"
                                                                        :value="inputValue" v-on="inputEvents" placeholder="M/D/YYYY" />
                                                                    <div @click="togglePopover()" class="input-group-append"><span
                                                                            class="input-group-text"><em class="fa fa-calendar"></em></span>
                                                                    </div>
                                                                </template>
                                                            </v-date-picker>
                                                            <span v-if="errors[0]" name="Date"
                                                                class="invalid-feedback d-block">{{ errors[0]
                                                                }}</span>
                                                        </Field>                                                    
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 float-left">
                                                    <div class="form-group">
                                                        <label>Policy Name:<span class="text-danger">*</span></label>                                      
                                                        <Field :rules="{ 'required': false }" class="w-100" name="Policy">      
                                                            <select name="Policy" v-model="txtPolicy" v-bind:class="{ 'form-control w-100': true }"> 
                                                                <option selected value="">Select Policy</option>                                              
                                                                <option v-for="(item, itemIndex) in PolicyList" :key="itemIndex" :value="item.ID">{{ item.POLICY_NAME }}</option>
                                                            </select>                                                          
                                                        </Field> 
                                                    </div>
                                                </div>                                                
                                                </div>
                                                <div class="col-md-12 p-0">
                                                <div class="row flex-row-reverse">
                                                    <div class="col-lg-6 text-right">
                                                        <a href="javascript:;" v-on:click="SavedUser(valid,errors);" title="Save" class="btn btn-success save-btn mr-1">
                                                            <em class="fa fa-save pr-2"></em>Save User
                                                        </a>
                                                        <a href="javascript:;" v-on:click="ClosedClosed(valid,errors)" class="btn btn-danger  delete-btn">
                                                            <em class="fa fa-times"></em> {{$t('Cancel')}}
                                                        </a>
                                                    </div>
                                                    <div class="col-lg-6">
                                                            <div class="py-2"><small class="text-danger">{{$t('MandatoryString')}}</small></div>
                                                    </div>
                                                </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>                                
                            </div>
                        </section>
        <AddChannelTag v-if="ShowAddChannelTag"></AddChannelTag>
    </div>
</template>
<script>
import moment from 'moment';
import DataService from "../../services/DataService";
import DynamicForm from "../Common/DynamicForm.vue";
import AddChannelTag from "../Wizard/Model/AddChannelPhaseTag.vue";
export default{  
    components: {
        DynamicForm, 
        AddChannelTag     
    },
    data(){
        return{
            ProjectTeamMembers:[],
            trnorecords:false,
            PMCanSeeProjectFinance:false,
            UserId:'',
            OverView:false,
            Status:1,
            id:0,
            isLoading: false, 
            TemplateList: [],
            TemplateById: [],
            View: false,
            ListView: false,
            TemplateId: null,
            TemplateName: null,
            TemplateDescription: null,
            ProjectFeature: null,
            ClickCustomise: false,
            Company_Id: null,
            TotalRecords: 0,
            PageNumber: 1,
            PageSize: 10,
            SortBy: '',
            SortExp: '',
            SearchText: '',
            TemplateSearchResetClick: '',
            CurrentPage: 1,
            ClickApply: false,
            ClickView: false,
            IsClickCustomBtn: false,
            step2: null,
            step3: null,
            TabSelected: 1,
            isEditCustomise: false,
            EventData: null,
            teamData: null,
            tagData: null,
            templateData: null,
            GroupBy: [],
            Channel: null,
            IndustryType: null,
            showaddeventcatagoryModal: false,
            showaddeventsubcatagoryModal: false,
            currentitem: [],
            currentsubitem: [],
            EventType: '',
            CategoryType: '',
            item_image: '',
            imageName: '',
            attachement_mime: '',
            fileName: '',
            base64String: '',
            EventCatagory: null,
            Status: null,
            submited: false,
            showEventImageForEdit: false,
            imageDetailsForEdit: '',            
            options: {               
                //format: String(Globalize.culture().calendar.patterns.d).toUpperCase(),
                useCurrent: true,
                showClear: true,
                showClose: true,
                allowInputToggle: true,
                daysOfWeekDisabled: [0, 6]
            },
            eventLocationVenueValidation: '',
            vSelectValidate: false,
            galleryImages: [],
            visible: false,
            index: 0,
            BaseRate: 0,
            disabledHourRate: false,
            currencytext: null,           
            fieldCode: '',
            SelectedCurrency: null,
            userGuide: '',
            HideCtrl: false,
            
            typeCode:'',
            ApiURL:'',
            ProjectEditId : 0,
            isShowSaveBUtton : true,
            AddMemberPopupCallFrom :"Project",
            ShowManageProject :true, 
            ShowAddChannelTag:false,
            modalChannelPhaseTag:null,
            AddTeamMember:false,
            UserType:null,
            UserId:null,
            UserForm:false,
            DDLList:[],
            GetUserType:[],
            RoleList:[],
            GetDetartment:[],
            PolicyList:[],
            txtTitle:'',
            txtGender:'',
            txtFirstName:'',
            txtLatName:'',
            txtEmail:'',
            txtUserType:'',
            txtPassword:'',
            txtCPassword:'',
            txtRole:'',
            txtDepartment:'',
            txtUserDate:'',
            txtPolicy:'',
            fullJson:[]

        }
    },
    created: async function(){        
        var vm=this;
        if (vm.$parent.ProjectEditId > 0) {
            var pid = vm.ProjectEditId;
            vm.ApiURL = "id=" + pid + "&moduleName=PROJECT&subModuleCode=Project_Detail&langCode=EN";
            vm.isEditCustomise = true;
        }
        else {
            if (typeof this.id == 'undefined') {
                vm.id = '';
            }
            vm.isEditCustomise = false;
            vm.ApiURL = "id=" + vm.id + "&moduleName=PROJECT&subModuleCode=Project_Detail&langCode=EN"
        }
        await vm.FetchDataTemplate();
        vm.FetchProjectData(vm.ApiURL); 
        vm.ProjectBaseRate();  
        vm.GetMemberListing();  
        vm.UserType = vm.GetUserInfo.UserType;
        vm.UserId=vm.GetUserInfo.UserId;          
        vm.GetUserTitle();
        vm.GetUserTypes();
        vm.GetAllRolesOfUserType();
        vm.GetAllDepartment();
        vm.GetAllPolicy();
    },
    methods:{
        SubmitData:function(){            
            var vm = this;
            vm.$refs.ProjectData.validate().then(result=>{                
                result=true;
                if(result){
                    vm.fullJson = vm.EventData;
                    var data = [];
                    var validation = [];
                    vm.fullJson.forEach(item => {                        
                    var validationObject = {
                        field_name: item.name,
                        required: item.is_required,
                        length: item.length,
                        type: item.validation_type
                    };
                    if (vm.ProjectEditId > 0) {
                        var fieldValue = (item.name == "project_id" ? vm.ProjectEditId : ((typeof item.value === "undefined") ? '' : item.value));
                    }
                    else {
                        var fieldValue = (item.name == "project_id" ? vm.ProjectEditId : ((typeof item.value === "undefined") ? '' : item.value));
                    }
                    if (item.name == 'is_ongoing') {
                        fieldValue = 0;
                    }
                    if (item.dt_code == "date") {
                        fieldValue = item.value;
                    }
                    if (item.name == "estimated_hours") {
                        fieldValue = item.value;
                    }
                    if (item.dt_code == "checkbox" && item.name == 'off_days') {                          
                        fieldValue = String(item.select_value);
                    }
                    if (item.dt_code == "checkbox" && item.name != 'off_days') {
                        fieldValue = String(item.select_value);
                    }
                    if (item.name == "project_team") {
                        // fieldValue = JSON.parse($("#hdnUserDataProject").val());
                    }
                    var dataObject = {
                        field_name: item.name,
                        field_value: fieldValue,
                        custom_field_id: item.custom_field_id
                    };
                    data.push(dataObject);
                    validation.push(validationObject);
                    });
                }
                    var postJSON = {
                        data: data,
                        validation: validation,
                        projectfeature: vm.ProjectFeature
                    };
                    var objectPostString = JSON.stringify(postJSON);
                    DataService.SaveProject(objectPostString).then(response=>{
                        if(response.data !=null){
                            if(response.data.code == "1"){
                                if(vm.ProjectEditId > 0){
                                    vm.ShowAlert(vm.$t('UpdatedSuccess',[vm.$t('Project')]), "success", true,  vm.$t("Alert"));
                                    vm.CloseTemplate();
                                }
                                else if (vm.roadmapname == "ProjectWizard") {
                                    vm.ShowAlert(vm.$t('AddedSuccess',[vm.$t('Project')]), "success", true,  vm.$t("Alert"));                                   
                                    vm.CloseTemplate();                                   
                                }
                                else{
                                    vm.ShowAlert(vm.$t('AddedSuccess',[vm.$t('Project')]), "success", true,  vm.$t("Alert"));                                   
                                    vm.CloseTemplate();
                                }
                            }
                            else{
                                vm.ShowAlert(vm.$t('AddedError',[vm.$t('Project')]), "failure", true,  vm.$t("Alert")); 
                            }
                        }
                        else{
                                vm.ShowAlert(vm.$t('AddedError',[vm.$t('Project')]), "failure", true,  vm.$t("Alert")); 
                            }
                    });
               
            });
        },
        SavedUser:function(valid,errors){            
            var vm=this;
            let userObj={
                Title:vm.txtTitle,
                Gender:vm.txtGender,
                FirstName:vm.txtFirstName,
                LatName:vm.txtLastName,
                Email:vm.txtEmail,
                UserType:vm.txtUserType,
                Password:vm.txtPassword,
                CPassword:vm.txtCPassword,
                Role:vm.txtRole,
                Department:vm.txtDepartment,
                UserDate:vm.txtUserDate,
                Policy:vm.txtPolicy
            };
            var url=JSON.stringify(userObj);
            vm.$refs.AddUserform.validate().then(result=>{                
                result=true;
                if(result){
                    DataService.SavedUser(url).then(response=>{                        
                        if(response.data.msg=='Add'){
                            vm.ShowAlert(vm.$t('AddedSuccess',[vm.$t('User')]), "success", true,  vm.$t("Alert"));
                            vm.ClosedClosed(); 
                        }
                        if(response.data.msg=='EmailExists'){
                            vm.ShowAlert(vm.$t('AlreadyExists',[vm.$t('Email')]), "failure", true,  vm.$t("Alert")); 
                        }
                    });
                }
            });
        },
        GetAllPolicy:function(){
            var url=`departmentId=`;
            DataService.GetAllPolicy(url).then(response=>{
                this.PolicyList=response.data;
            });
        },
        GetAllDepartment:function(){            
            DataService.GetAllDepartment().then(response=>{
                this.GetDetartment=response.data;
            });
        },
        GetAllRolesOfUserType:function(){            
            var url=`userType=3`;
            DataService.GetAllRolesOfUserType(url).then(response=>{                
                this.RoleList=response.data;
            })
        },
        GetUserTypes:function(){            
            let url=`filterby=MANAGEUSER`;
            DataService.GetUserTypes(url).then(response=>{                
                this.GetUserType=response.data;    
            });
        },
        GetUserTitle:function(){            
            DataService.GetUserTitle().then(response=>{                
                this.DDLList=response.data;
            });
        },
        AddUser:function(){            
            this.UserForm=true;
        }, 
        ClosedClosed:function(){
            this.UserForm=false;
        },
        GetMemberListing:function(){            
            var vm=this;
            var url=`search=Project&projectId=`;
            DataService.GetMemberListing(url).then(response=>{                
                vm.ProjectTeamMembers=response.data;
            });
            setTimeout(function () {vm.ResponsiveDataTable('mainTable')}, 100);
            setTimeout(function () { vm.CheckBoxBootstrap() }, 100);                   
        },
        Step3: function () {                       
            var vm = this;           
            var data = $("select[name='currency_id'] :selected").text();           
            if (data == "") {
                data = vm.SelectedCurrency;
            }
            if (data != "Select") {
                var currencyValue = data.split("(");
                var curr = currencyValue[1].split(")");
                var currency = curr[0];

            }
            else {
                var currency = "";
            }

            if (vm.isEditCustomise) {
                var url = "currency=" + currency + "&module=" + vm.ProjectEditId;
            }
            else {
                var url = "currency=" + currency;
            }
            vm.AddTeamMember=true;            
        },
        NextStep3: function (valid,errors) {            
            var vm = this;
            vm.$refs.ProjectData.validate().then(function (result) {
                vm.vSelectValidate = true;
                result=true;
                if (result) {                
                    vm.step3 = '#step3';
                    vm.TabSelected = 3;
                    // if (vm.ProjectEditId > 0) {
                    //     if ($("#AddTeamMemberHtml").html().length == 0) {
                    //         vm.Step3();
                    //     }
                    // } 
                    vm.Step3();                   
                       // GetWalkThrough(baseUrl + "api/Common/GetWalkThroughViewOrNot", "Project/Index", 'AddPage', 7); 
                }
                else {                    
                    vm.ShowAlert(vm.$t("CorrectHighlightedErrors"), "warning", true, vm.$t("Alert"));
                }
            });
            vm.$parent.isShowSaveBUtton = true;
           // $("#hdnfrom").val("");
        },
        BackStep: function (step) {            
            var vm = this;
            if (step == 1) {

                vm.step1 = '#step1';
                vm.TabSelected = 1;
            }
            else if (step == 2) {
                vm.step2 = '#step2';
                vm.TabSelected = 2;
            }
            else {
                vm.step1 = '#step1';
                vm.TabSelected = 1;
            }


            vm.$parent.isShowSaveBUtton = true;
            $("#hdnfrom").val("");
        }, 
        SetCheckedValue: function (option, item, evt) {            
            var vm = this;
            var valuesArray = $('.offdays input:checkbox:checked').map(function () {
                return this.value;
            }).get().join(",");
            var offdaysArray = String(valuesArray).split(',');
            var stringDaysDisabled = '';
            if (offdaysArray != null && offdaysArray.length > 0) {

                $.each(offdaysArray, function (index, item) {
                    if (stringDaysDisabled.length > 0) {
                        stringDaysDisabled += ",";
                    }
                    if (item == "Sunday") {
                        stringDaysDisabled += 0;
                    }
                    else if (item == "Monday") {
                        stringDaysDisabled += 1;
                    }
                    else if (item == "Tuesday") {
                        stringDaysDisabled += 2;
                    }
                    else if (item == "Wednesday") {
                        stringDaysDisabled += 3;
                    }
                    else if (item == "Thursday") {
                        stringDaysDisabled += 4;
                    }
                    else if (item == "Friday") {
                        stringDaysDisabled += 5;
                    }
                    else if (item == "Saturday") {
                        stringDaysDisabled += 6;
                    }
                });
            }
            vm.options.daysOfWeekDisabled = false;
            if (stringDaysDisabled != '' || stringDaysDisabled != null) {
                var arrayData = stringDaysDisabled.split(',').map(function (x) { return parseInt(x) });
                vm.options.daysOfWeekDisabled = arrayData;
            }

            // option.first_value=valuesArray;
            item.select_value = valuesArray;
            item.value = valuesArray;
            if (evt.target.checked) {
                option.first_value = 1;
                $(evt.target).prop("checked", true);
            }
            else {
                option.first_value = 0;
                $(evt.target).prop("checked", false);
            }

            if (vm.EventData != null) {
                var locationId = $.grep(vm.EventData, function (element, index) {
                    if (element.name == 'location_id') {
                        return element.value;
                    }
                });


                var off_days = $.grep(vm.EventData, function (element, index) {
                    if (element.dt_code == 'checkbox' && element.name == 'off_days') {
                        return element.select_value;
                    }
                });
                var startDate = $.grep(vm.EventData, function (element, index) {
                    if (element.name == 'planned_start_date') {
                        return element.value;
                    }
                });
                var endDate = $.grep(vm.EventData, function (element, index) {
                    if (element.name == 'planned_end_date') {
                        return element.value;
                    }
                });
                locationId = locationId.length == 0 ? 0 : locationId[0].value;
                if (item.select_value == "") {
                    vm.GetProjectWorkingDays(locationId, "", moment(startDate[0].value, 'MM/DD/YYYY').format('MM/DD/YYYY'), moment(endDate[0].value, 'MM/DD/YYYY').format('MM/DD/YYYY'));
                }
                else {
                    vm.GetProjectWorkingDays(locationId, off_days[0].select_value, moment(startDate[0].value, 'MM/DD/YYYY').format('MM/DD/YYYY'), moment(endDate[0].value, 'MM/DD/YYYY').format('MM/DD/YYYY'));
                }  

            }
        },     
        setSelectedValue: function (item, val) { 
            var vm = this;            
            try {
                if (item.name == 'tag_id') {
                    var result1 = [];
                    $.each(item.select_value, function (i, e) {
                        var matchingItems = $.grep(result1, function (item1) {
                            return item1.name === e.name && item1.value === e.value;
                        });
                        if (matchingItems.length === 0) {
                            result1.push(e);
                        }
                    });
                    item.select_value = result1;
                }
                if (item.name == 'currency_id') {                   
                    vm.Step3();
                    $("#hdnfrom").val("");
                }
                if (item.name == "event_type_id") {
                    this.EventType = item.value;
                    this.currentitem = item;
                }
                if (item.name == "event_cat_id") {
                    this.CategoryType = item.value;
                    this.currentsubitem = item;
                }
                if (item.name == "event_location") {
                    if (item.value == "Venue") {
                        this.eventLocationVenueValidation = "required";
                    } else {
                        this.eventLocationVenueValidation = "";
                        $.each(this.GroupBy, function (key, parentItem) {
                            $.each(parentItem.EventData, function (childKey, childItem) {
                                if (childItem.name == 'venue_id') {
                                    childItem.dependent_show_type = 0;

                                }
                            });
                        });
                    }
                }
                if (Array.isArray(val)) {
                    if (val.length > 1) {
                        item.value = "";
                        var data1 = $.grep(val, function (el, index) {
                            return index === $.inArray(el, val);
                        });
                        $.each(data1, function (index, valItem) {
                            if (item.value == null || item.value == "") {
                                item.value = valItem.value;

                            } else {
                                item.value += "," + valItem.value;
                            }
                        })
                    } else {
                        if (val.length > 0) {
                            item.value = val[0].value;
                        }
                        else {
                            item.value = "";
                        }

                    }
                }
                else {
                    if (item.has_dependent > 0) {
                        $.each(this.GroupBy, function (key, parentItem) {
                            $.each(parentItem.EventData, function (childKey, childItem) {
                                if (childItem.parent_id == item.custom_field_id && childItem.dependent_type == 'fill') {
                                    vm.ShowSearchData('', vm, childItem, item.value);
                                }
                                if (childItem.parent_id == item.custom_field_id && childItem.dependent_type == 'show') {
                                    vm.ShowSearchData('', vm, childItem, item.value);
                                }
                            });
                        });
                    }
                    item.value = val;
                    $.each(this.GroupBy, function (key, parentItem) {
                        $.each(parentItem.EventData, function (childKey, childItem) {
                            if (childItem.parent_id == item.custom_field_id && childItem.dependent_type == 'show') {
                                var setValue = 0
                                childItem.dependent_show_type = setValue;

                                if ((item.value == "3" && childItem.name == 'week_day') || (childItem.name == 'recurring_date' && (item.value == "4" || item.value == '5')) || (childItem.name == 'location_id' && item.value == 'Venue') || (childItem.name == 'venue_id')) {
                                    setValue = 1;
                                    childItem.dependent_show_type = setValue;
                                    this.$validator.flag(item.name, {
                                        invalid: false
                                    });
                                }
                            }
                        });
                    });
                }
            }
            catch (ex) {
            }
        },
        onChangeOpenAddChannelTag:function(item){                      
            this.ShowAddChannelTag=true;
            this.modalChannelPhaseTag = item;
        },
        CloseAddChannelTag:function(){             
            this.ShowAddChannelTag=false;
        },
        FetchProjectData:function(ths){ 
            var vm=this;   
            vm.isLoading = true;
            DataService.FetchProjectData(ths).then((response)=>{     
                vm.isLoading = false;                 
                vm.EventData = response.data.data;
                vm.teamData = response.data.teamData;            
                vm.templateData = response.data.templateData;
                vm.tagData = response.data.tagData;                
                if (vm.id > 0) {
                    vm.imageDetailsForEdit = response.data.imageData;
                    if (vm.imageDetailsForEdit.length > 0) {
                        vm.galleryImages.push(vm.imageDetailsForEdit[0].FILE_PATH);                      
                    }
                }                
                if (vm.EventData != null && vm.EventData.length > 0) {
                    vm.EventData.forEach(function (x) { x.isPlusIcon == false });
                    vm.EventData.forEach(function (x) { x.is_Disabled = false });
                    var groupId = vm.EventData[0].group_id;
                        vm.EventData.forEach(item => {
                        if (item.name == 'tag_id' || item.name == "channel_id" || item.name == "priority_id") {
                            item.isPlusIcon = true;
                        }
                        if (item.name == 'is_hourly' && item.value == 0) {
                            vm.disabledHourRate = true;
                        }
                        if (item.name == 'is_hourly' && item.value == 1) {
                            vm.disabledHourRate = false;
                        }
                        if (item.name == "estimated_cost") {
                            var BudgetRate = item.value.slice(0, 14);
                            var BudgetLenght = parseInt(item.value.slice(0, 14)).length;

                            if (BudgetLenght <= 5) {
                                item.value = parseFloat(BudgetRate).toFixed(2);
                            }
                            else if (item.value == '0.000') {
                                item.value = parseFloat(BudgetRate).toFixed(2);
                            }
                            else {
                                item.value = BudgetRate;
                            }
                        }
                        if (item.name == "base_rate" && item.value == "") {                           
                            item.value = vm.BaseRate;
                        }
                        if (item.dt_code == 'checkbox' && item.name == 'off_days') {
                            var select_options = vm.MakeArray(item.select_options, item.dt_code);
                            item.select_options = select_options;
                            if (vm.ProjectEditId > 0) {
                                if (item.value != "" && item.value != null) {
                                    item.select_value = item.value;
                                }
                                else {
                                    item.select_value = "";
                                }
                            }
                            else {
                                if (item.value != "" && item.value != null) {
                                    item.select_value = item.value;
                                }
                                else {
                                    item.value = "Sunday,Saturday";
                                    item.select_value = item.value;
                                }
                            }                            
                        }
                        if (item.dt_code == 'checkbox' && item.name != 'off_days') {
                            if (item.value != '') {
                                item.select_value = item.value;
                            }
                        }
                        if (item.name == 'planned_start_date') {
                            if (item.value != "") {
                                item.value = vm.formatDateEdit(item.value);
                            }
                            else {
                                item.value = vm.pstartdate;
                            }

                        }
                        if (item.name == 'status_id' && item.value == "") {
                            item.value = 1001;
                        }
                        if (item.name == 'planned_end_date') {
                            if (item.value != "") {
                                item.value = vm.formatDateEdit(item.value);
                            }
                            else {
                                item.value = vm.penddate;
                            }
                        }
                        if (item.name == 'tag_id' && item.field_code == 'HR_TAG') {
                            // vm.MultiDropdownFill(item);                            
                        }
                        if (item.name == 'currency_id') {
                            if (item.value != "") {
                                var data = $.grep(JSON.parse(item.select_options), function (element, index) {
                                    return element.value == item.value;
                                });
                                if (typeof data[0] == "undefined") {
                                    vm.SelectedCurrency = "United States dollar (USD)";
                                }
                                else {
                                    vm.SelectedCurrency = data[0].name;
                                }
                                this.Step3();
                            }
                        }
                        if (item.dropdown_type == "normal" && item.picklist_options == "Lookup" && item.name == "event_reminder_id") {
                            var reminder_option = vm.MakeNormalArray(item.select_options);
                            $.each(reminder_option, function (ind, dropItem) {
                                reminder_option[ind].name = Globalize.localize(dropItem.name);
                            });
                            item.select_options = reminder_option;
                        }
                        if (item.name == "event_cat_id" || item.name == "event_sub_cat_id") {
                            item.isPlusIcon = true;
                        }
                        groupId = item.group_id;
                        var chkGroup = $.grep(vm.GroupBy, function (element, index) {
                            return element.group_id == groupId;
                        });
                        if (chkGroup.length == 0) {
                            var returnedData = $.grep(vm.EventData, function (element, index) {
                                return element.group_id == groupId;
                            });
                            $.each(returnedData,function (key, childItem) {
                                if (childItem.parent_id > 0 && childItem.dependent_type == 'show') {
                                    var setValue = 0

                                    var chkParentValue = $.grep(vm.EventData, function (element, index) {
                                        return element.custom_field_id == childItem.parent_id;
                                    });
                                    childItem.dependent_show_type = setValue;
                                    if (chkParentValue.length > 0) {
                                        if ((chkParentValue[0].value == "1" && childItem.name == 'event_recurring_id') || (chkParentValue[0].value == "3" && childItem.name == 'week_day') || (childItem.name == 'recurring_date' && (chkParentValue[0].value == "4" || chkParentValue[0].value == '5'))) {
                                            setValue = 1;
                                        }
                                    }
                                    childItem.dependent_show_type = setValue;
                                }
                            });
                            var returnedCheckBox = $.grep(vm.EventData, function (element, index) {
                                return element.dt_code == 'checkbox';
                            });
                            if (returnedCheckBox.length > 0) {
                                returnedCheckBox.map(function (a) {
                                    a.select_value = [];
                                })
                            }
                            if (vm.id >0) {
                                $.each(returnedData, function (filedIndex, fieldItem) {
                                    if ((fieldItem.dropdown_type == "custom" || fieldItem.dropdown_type == "multi") && fieldItem.picklist_options == "Lookup") {
                                        fieldItem.select_options = vm.MakeNormalArray(fieldItem.select_options);
                                        fieldItem.select_value = fieldItem.select_options;

                                        if (fieldItem.select_options != null && fieldItem.select_options.length > 0) {
                                            fieldItem.value = fieldItem.select_options[0].value;
                                        }
                                    }
                                    else if (fieldItem.dt_code == "decimal" && fieldItem.form_field_visibility == "YES" && fieldItem.value > 0) {
                                        fieldItem.value = parseFloat(fieldItem.value, 10).toFixed(2);
                                    }
                                    else if (fieldItem.dt_code == "date") {
                                        fieldItem.value = vm.formatDate(fieldItem.value);
                                    }
                                    else if (fieldItem.dt_code == "datetime") {
                                        fieldItem.value = vm.formatDateTimeFunc(fieldItem.value);
                                    }
                                    if (fieldItem.name == "event_type_id") {
                                        $.each(returnedData, function (filedIndex1, fieldItem1) {
                                            if (fieldItem1.name == 'event_cat_id') {
                                                vm.ShowSearchData('', vm, fieldItem1, fieldItem.value);
                                            }
                                        });
                                    }
                                    if (fieldItem.name == "event_cat_id") {
                                        $.each(returnedData, function (filedIndex1, fieldItem1) {
                                            if (fieldItem1.name == 'event_sub_cat_id') {
                                                vm.ShowSearchData('', vm, fieldItem1, fieldItem.value);
                                            }
                                        });
                                    }
                                    if (fieldItem.name == "event_location") {                                      
                                        if (fieldItem.value == 'Venue') {
                                            $.each(returnedData, function (filedIndex1, fieldItem1) {
                                                if (fieldItem1.name == 'location_id' || fieldItem1.name == 'venue_id') {
                                                    fieldItem1.dependent_show_type = 1;
                                                }

                                            });
                                        }
                                    }                                   
                                    if (fieldItem.name == "is_recurring" && fieldItem.value == "1") {
                                        fieldItem.is_Disabled = true;
                                        $.each(returnedData, function (filedIndex1, fieldItem1) {
                                            if (fieldItem1.name == "event_recurring_id" || fieldItem1.name == "week_day" || fieldItem1.name == "recurring_date") {
                                                fieldItem1.is_Disabled = true;
                                            }
                                        });
                                    }
                                    
                                });
                            }
                            if (returnedData.length > 0) {
                                    var objGroup = {
                                        group_id: groupId,
                                        group_name: returnedData[0].group_name,
                                        group_display_name: returnedData[0].group_display_name,
                                        Data: returnedData
                                };
                                    vm.GroupBy.push(objGroup);
                            }
                        }
                    });                                      
                    if (vm.EventData != null) {                        
                        var locationId = $.grep(vm.EventData, function (element, index) {                            
                            if (element.name == 'location_id') {                                
                                return element.value;
                            }
                        });
                       
                        var off_days = $.grep(vm.EventData, function (element, index) {
                            if (element.dt_code == 'checkbox' && element.name == 'off_days') {
                                return element.value;
                            }
                        });
                       
                        var startDate = $.grep(vm.EventData, function (element, index) {
                            if (element.name == 'planned_start_date') {
                                return element.value;
                            }
                        });
                       
                        
                        var endDate = $.grep(vm.EventData, function (element, index) {
                            if (element.name == 'planned_end_date') {
                                return element.value;
                            }
                        });                      
                        
                         locationId = locationId.length == 0 ? 0 : locationId[0].value;
                        off_days = off_days.length == 0 ? "" : off_days[0].value;
                        // vm.GetProjectWorkingDays(locationId, off_days, moment(startDate[0].value, 'MM/DD/YYYY').format('MM/DD/YYYY'), moment(endDate[0].value, 'MM/DD/YYYY').format('MM/DD/YYYY'));
                   
                    }
                }
            });
        },
        FetchDataTemplate:async function () {            
            var vm = this;
            vm.isLoading=true;
            var url = 'searchtext=' + vm.SearchText + '&id=' + vm.ProjectEditId + '&sortBy=' + vm.SortBy + '&sortExp=' + vm.SortExp + '&pageSize=' + vm.PageSize + '&pageNumber=' + vm.PageNumber;
             DataService.FetchDataTemplate(url).then(response=> {  
                vm.isLoading=false;              
                if (response.data != null && response.data.result.FeatureList.length > 0) {
                    vm.TemplateList = response.data.result.FeatureList;                    
                    $.each(vm.TemplateList, function (index, element) {
                        if (vm.templateData != null && typeof vm.templateData != 'undefined' && vm.templateData.length > 0) {
                            if (element.id == vm.templateData[0].template_id) {                                
                                element.SetTemplate = 1;
                                element.EditableData = 1;
                                var mainTemplateData = {
                                    templateid: vm.templateData[0].template_id,
                                };
                                vm.ProjectFeature = mainTemplateData;
                            }
                            else {
                                element.EditableData = 0;
                            }
                            vm.isEditCustomise = true;
                        }
                        else {
                            element.EditableData = 0;
                        }
                    });                    
                    if (vm.TemplateSearchResetClick == '') {
                        if (vm.$parent.ProjectEditId > 0) {
                            if (vm.ProjectFeature == null) {
                                vm.step1 = '#step1';
                                vm.TabSelected = 1;
                            } else {
                                vm.TabSelected = 2;
                                vm.step2 = '#step2';
                                vm.step3 = '#step3';
                            }
                        }
                    }
                    else {
                        vm.TemplateSearchResetClick = '';
                    }
                }
                else {
                    vm.TemplateList = []; 
                    vm.isLoading=false;                  
                }
               // vm.FetchProjectData(vm.ApiURL);
            })
        },
        Customise:function(){            
            var vm = this;
            vm.ProjectFeature = null;
            DataService.TemplateMasterListing().then((response)=>{                
                if (response.data != null && response.data.result.data.length > 0) {
                    vm.TemplateById = response.data.result.data;               
                    vm.TemplateName = null;
                    vm.TemplateDescription = null;
                    vm.View = true;
                    vm.ClickCustomise = true;
                    vm.IsClickCustomBtn = true;
                    vm.ClickView = false;
                    vm.ClickApply = false;
                }
            });
        },
        CancelTemplate: function () {            
            var vm = this;
            vm.View = false;
            vm.IsClickCustomBtn = false;
            vm.ClickView = false;
            vm.ClickApply = false;
            vm.ProjectFeature = null;
            vm.step2 = null;
            vm.step3 = null;
            vm.FetchDataTemplate();
        },
        Next: function () {
           
           var vm = this;
            if (vm.IsClickCustomBtn) {
                vm.$refs.ProjectData.validate("TemplateData").then(function (result) {
                    if (result) {

                        var mainTemplateData = {
                            templateid: null,
                            name: vm.TemplateName,
                            description: vm.TemplateDescription,
                            features: vm.TemplateById
                        };
                        vm.ProjectFeature = mainTemplateData;
                        if (vm.ProjectFeature != null) {
                            vm.step2 = '#step2';
                            vm.TabSelected = 2;
                        }

                    }
                });
            }
            else if (vm.ClickView && vm.Company_Id != null) {
                vm.$validator.validate("TemplateData").then(function (result) {
                    if (result) {

                        var mainTemplateData = {
                            templateid: vm.TemplateId,
                            name: vm.TemplateName,
                            description: vm.TemplateDescription,
                            features: vm.TemplateById
                        };
                        vm.ProjectFeature = mainTemplateData;
                    }
                });
            }
            else if (vm.ClickView && vm.Company_Id == null) {
                vm.$refs.form.validate().then((valid)=> {
                    if (valid) {
                var mainTemplateData = {
                    templateid: vm.TemplateId,
                };
                vm.ProjectFeature = mainTemplateData;
            }
        });
            }
            else if (vm.ClickApply) {
                var mainTemplateData = {
                    templateid: vm.TemplateId,
                };
                vm.ProjectFeature = mainTemplateData;
            }
            else {
                if (vm.ProjectFeature == null) {
                    vm.ShowAlert("Please select a template", "warning", true, vm.$t("Alert"));
                    return false;
                }
            }
            if (vm.$parent.ProjectEditId > 0) {
                if (vm.ProjectFeature != null) {
                    vm.step2 = '#step2';
                    vm.TabSelected = 2;
                    //vm.step3 = '#step3';
                }
            }
            else {
               
                if (vm.ProjectFeature != null) {
                    vm.step2 = '#step2';
                    vm.TabSelected = 2;
                }
            
            }

        },
        ApplyTemplate: function (item, event) {
            var vm = this;
            vm.ClickApply = true;
            vm.ProjectFeature = null;
            $.each(vm.TemplateList, function (index, element) {
                element.SetTemplate = 0;
            });        
            item.SetTemplate = 1;
            var mainTemplateData = {
                templateId: item.id,
            };
            vm.TemplateId = item.id;
            vm.ProjectFeature = mainTemplateData;
            vm.IsClickCustomBtn = false;
            vm.ClickView = false;
        },
        ViewTemplate: function (id, editTemplate) { 
            var vm = this;
            vm.TemplateId = id;
            var url = 'templateId=' + id + '&editTemplate=' + editTemplate + '&projectid=' + vm.ProjectEditId;
           DataService.TemplateByTemplateId(url).then(function (response) {                
                if (response.data != null && response.data.result.DATA.length > 0) {
                    vm.TemplateById = [];
                    vm.TemplateById = response.data.result.DATA;                    
                    vm.TemplateName = vm.TemplateById[0].NAME;
                    vm.TemplateDescription = vm.TemplateById[0].DESCRIPTION;
                    vm.Company_Id = vm.TemplateById[0].COMPANY_ID;
                    if (vm.Company_Id != null) {
                        vm.ClickCustomise = true;
                    }
                    else {
                        vm.ClickCustomise = false;
                    }
                    vm.View = true;
                    vm.ClickView = true;
                    vm.IsClickCustomBtn = false;
                    vm.ClickApply = false;
                }
            });
        },
        ResetTemplate: function () {            
            var vm = this;
            vm.SearchText = '';
            vm.TemplateSearchResetClick = 'click';
            vm.FetchDataTemplate();
        },
        SearchTemplate: function () {            
            var vm = this;
            vm.TemplateSearchResetClick = 'click';
            vm.FetchDataTemplate();
        },
        ProjectBaseRate:function(){            
            var vm=this;
            DataService.ProjectBaseRate().then(response=>{                
                if(response.data.status=='Success'){
                    vm.BaseRate= response.data.result.DATA[0].CONFIG_DATA_VALUE
                }
            });
        },
        GetProjectWorkingDays:function(locationId, offDays, startDate, endDate){            
            var vm=this;
            var url=`locationId=${locationId}&offDays=${offDays}&startDate=${startDate}&endDate=${endDate}`;
            DataService.GetProjectWorkingDays(url).then(response=>{
            });
        },
        checkboxAlias:function(){
            $(this).closest('tr').removeClass('is-invalid');
            if ($(this).prop('checked') == true) {
                $(this).closest("tr").find('input.checkboxid:checkbox').attr('showAlias', 'yes');
            }
            else {
                $(this).closest("tr").find('input.checkboxid:checkbox').attr('showAlias', 'no');
            }
        },
        checkboxPM:function(){
            $(this).closest('tr').removeClass('is-invalid');
            if ($(this).prop('checked') == true) {
                $(this).closest("tr").find('input.checkboxid:checkbox').attr('isPM', 'yes');
            }
            else {
                $(this).closest("tr").find('input.checkboxid:checkbox').attr('isPM', 'no');
            }
        },
        setcheckall:function () {
            var checkboxesInput = $(this).find('input.setcheckall:checkbox');            
            if ($(this).is(':checked')) {
                $("table#mainTable tbody tr .chkItems:checkbox").prop('checked', false);
                $("table#mainTable tbody tr:visible .chkItems:checkbox").prop('checked', true);
                var array = $('#SelectedTeam').val().split(",");
                if (array != "") {
                    $.each(array, function (i) {
                        $("table#mainTable tbody tr .chkItems:checkbox[value=" + array[i] + "]").prop('checked', true);
                    });
                }
                $(".chkDefault").removeAttr("disabled");
                $(".chkDefault").next().removeClass("disableoff").addClass("off");
                $(".chkAlias").removeAttr("disabled");
                $(".chkAlias").next().removeClass("disableoff").addClass("off");
            } else {
                $(".chkDefault").attr('disabled', 'disabled').prop("checked", false).removeAttr("checked");
                $(".chkDefault").next().removeClass("off").addClass("disableoff");
                $(".chkAlias").attr('disabled', 'disabled').prop("checked", false).removeAttr("checked");
                $(".chkAlias").next().removeClass("off").addClass("disableoff");
                $("#mainTable input.checkboxid").attr('showAlias', 'no');
                $("#mainTable input.checkboxid").attr('isPM', 'no');
            }
        },
        setcheckSingle:function (i) {  
               
        }
    }
}
</script>