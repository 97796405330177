<script>
import LeadProductService from "../../../services/DataService";
export default {
  data() {
    return {
        tagname: "",
        tagId:"",
        TagsArray:[],
        showTagDropdown:false
    };
  },
  created: function () {
    var vm = this;
    vm.GetTagsByModuleId();
  },
  methods: {
    SaveTag: function () {
      var vm = this;
      this.$refs.tagForm.validate().then((result) => {
        if (result) {
          if (vm.tagname.length > 0) {
            var jsonobj = {
              TagId: vm.tagId,
              tagName: vm.tagname.toLowerCase(),
              tagDesc: '',
              tagStatusId: 1001,
              moduleName: 'Asset'
            };
            var objectPostString = JSON.stringify(jsonobj);
            LeadProductService.SaveTagName(objectPostString).then(function (response) {
              if (response.data[0].Status == "Success") {
                vm.GetTagsByModuleId();
                vm.$parent.tagData.push({ tagId: vm.tagId, tagname: vm.tagname.toLowerCase() });
                vm.$emit("close");
              } else if (response.data[0].Status == "Exist") {
                let checkIfExists = vm.$parent.tagData.find((x) => { return x.tagname.toLowerCase() == vm.tagname.toLowerCase() });
                if (typeof (checkIfExists) == 'object') {
                  vm.ShowAlert(vm.$t('TagAlreadyExist'), "failure", true, vm.$t('Alert'));
                }
                else {
                  vm.$parent.tagData.push({ tagId: vm.TagsArray.filter(x => x.text.toLowerCase() == vm.tagname.toLowerCase())[0].value, tagname: vm.tagname.toLowerCase() });
                  vm.$emit("close");
                }
              }
            });
          }
          else {
            vm.ShowAlert(vm.$t('TagNameIsRequired'), "failure", true, vm.$t('Alert'));
          }
        }
      });
    },
    setTagName:function(event){
      var vm = this;
      vm.tagId=event.value;
      vm.tagname=event.text;
      vm.showTagDropdown=false;
    },
    GetTagsByModuleId:function(){
      var vm = this;
      LeadProductService.GetCRMTagsByModuleId('moduleName=Asset').then(function(response){
        vm.TagsArray = response.data;
      })
    },
    showTagList:function(){
      var vm= this;
      vm.showTagDropdown ? vm.showTagDropdown=false : vm.showTagDropdown=true;
    },
    searchTag() {
      var vm = this;
      vm.GetTagsByModuleId();
      vm.showTagDropdown=true;
      setTimeout(function(){
        vm.TagsArray= vm.TagsArray.filter(x=>x.text.toLowerCase().includes(vm.tagname.toLowerCase()))
    },300);
    }
  },
};
</script>
<template>
  <transition name="modal">
    <div class="my-popups">
      <div class="modal d-block">
        <div class="modal-dialog modal-dialog-centered" style="max-width:900px !important">
          <div class="modal-content">
            <div class="modal-header theme-primary partition-full">
              <h4 class="modal-title">{{$t('Tag')}} {{$t('Name')}}</h4>
              <button type="button" class="close" v-on:click="$emit('close')">
                <em class="fa fa-times" aria-hidden="true"></em>
              </button>
            </div>
            <div class="modal-body">
              <Form ref="tagForm" autocomplete="off" v-slot="{ valid,errors}">
                <div class="col-md-12 float-left px-0">
                  <div class="row" style="max-height: 450px;">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label><span id="">Title :</span></label><span class="text-danger">*</span>
                            <Field name="TagName" :rules="{'required':true}" label="Title" v-slot="{errors}">
                              <div class="input-group mb-3" style="position: relative;">
                                <input  name="TagName" id="TagName" type="text" v-bind:class="{ 'form-control': true,'is-invalid': errors[0]}" v-model="tagname" v-on:keyup="searchTag"/>
                                <div class="unique_dynamicdatalist">
                                  <ul v-for="(item,index) in TagsArray" @click="setTagName(item)" style="z-index: auto;" :key="index">
                                    <li  v-if="showTagDropdown" style="text-transform: capitalize;" :value="item.value">{{item.text}}</li>
                                  </ul>
                                </div>
                                <a class="btn float-left" href="javascript:;" style="background-color: #fff;color: #303030;border-color: #abb5bf;border-left: none;" @click="showTagList"><em class="dropdown-toggle float-right"></em></a>
                              </div>
                            </Field>
                            <span   name="TagNAme"  class="invalid-feedback d-block">{{errors[0]}}</span>    
                        </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
            <div class="modal-footer">
              <div class="col-md-12 p-0">
                <div class="row flex-row-reverse">
                  <div class="col-lg-6 text-right">
                    <a  href="javascript:;" v-on:click="SaveTag();" title="Save" class="btn btn-success save-btn mr-2" >
                      <em class="fa fa-save pr-2"></em>{{$t('Save')}}</a>
                    <a href="javascript:;" v-on:click="$emit('close')" class="btn btn-danger  delete-btn">
                      <em class="fa fa-times pr-2"></em>{{$t('Cancel')}} </a>
                  </div>
                  <div class="col-lg-6">
                    <div class="py-2">
                      <small class="text-danger">{{ $t("MandatoryString")}} </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>